<template>
  <div class="content-table-mobile" v-if="!bEmptyTable">
    <div v-if="bLoadingTable" class="content-progress-linear">
      <v-progress-linear indeterminate color="yellow darken-2"></v-progress-linear>
    </div>
    <div v-else v-for="(itemTable, index) in aTable" :key="index">
      <v-container>
        <div class="content-all-first">
          <div class="content-left-first">
            {{ itemTable.sName }}
          </div>
          <div class="content-right-first">
            <!-- <v-menu
              attach
              bottom
              left
              :close-on-content-click="false"
              offset-y
              rounded="lg"
            >
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon> mdi-dots-vertical </v-icon>
                </v-btn>
              </template>
              <v-card class="content-accion-menu">
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      v-for="(item, index) in aMenuAccion"
                      :key="index"
                      class="content-accion"
                    >
                      <span @click="goAccion(itemTable, item.action)">
                        <span
                          ><v-icon :style="{ color: item.color }">{{
                            item.icon
                          }}</v-icon></span
                        >
                        <span
                          :style="{ color: item.color }"
                          class="text-menu-accion"
                          >{{ item.text }}</span
                        >
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-menu> -->
            <menu-accion-table-component-global @openDetailItem="openDetailItem" @openDeleteItem="openDeleteItem"
              :aMenuAccion="aMenuAccion" :itemTable="itemTable" />
          </div>
        </div>
        <div class="content-all">
          <div class="content-left">
            <p>Nombre del encargado</p>
          </div>
          <div class="content-right">
            <p class="text-right">{{ itemTable.sNameManager }}</p>
          </div>
        </div>
        <div class="content-all">
          <div class="content-left">
            <p>Teléfono del encargado</p>
          </div>
          <div class="content-right">
            <p class="text-right">{{ itemTable.sPhone }}</p>
          </div>
        </div>
        <div class="content-all">
          <div class="content-left">
            <p>Correo del encargado</p>
          </div>
          <div class="content-right">
            <p class="text-right">{{ itemTable.sEmail }}</p>
          </div>
        </div>

        <div class="content-all">
          <div class="content-left">
            <p>Ubicación﻿</p>
          </div>
          <div class="content-right">
            <p class="text-right">{{ itemTable.sLocation }}</p>
          </div>
        </div>
      </v-container>
      <v-divider class="divider-global mt-3 mb-3"></v-divider>
    </div>

    <footer-table-component-global :numPages="numPages" :totalItems="totalItems" @changeTable="changeTable"
      :bChangeSelectedItem="bChangeSelectedItem" />
  </div>
</template>

<script>
export default {
  props: {
    headers: Array,
    aTable: Array,
    numPages: Number,
    totalItems: Number,
    bChangeSelectedItem: Boolean,
  },
  data() {
    return {
      bLoadingTable: true,
      bEmptyTable: false,
      iCurrentPage: 1,
      iPageNumber: 1,
      iItemsPerPage: 30,
      totalView: 0,
      aMenuAccion: [
        {
          text: "Detalle",
          icon: "mdi-eye",
          toGo: "ProviderDetail",
          color: "var(--primary-color-text-menu-detail) !important",
          action: 1,
        },
        {
          text: "Eliminar",
          icon: "mdi-trash-can-outline",
          toGo: "ProviderDetail",
          color: "var(--primary-color-btn-table-delete) !important",
          action: 2,
        },
      ],
    };
  },
  beforeMount() {
    this.setLoadingTable();
  },
  methods: {
    setLoadingTable() {
      this.bLoadingTable = true;
      this.bEmptyTable = false;
      setTimeout(() => {
        this.bLoadingTable = false;
        this.bEmptyTable = this.aTable.length < 1;
      }, 1000);
    },
    openDetailItem(item) {
      this.$emit("openDetailItem", item);
    },
    openDeleteItem(item) {
      this.$emit("openDeleteItem", item);
    },
    changeTable(obj) {
      this.$emit("changeTable", obj);
    },
  },
  watch: {
    aTable() {
      this.setLoadingTable();
    }
  }
};
</script>

<style >
.content-all-first {
  display: flex;
  width: 100%;
  color: var(--primary-color-text) !important;
  margin-bottom: 10px;
}

.content-all {
  display: flex;
  width: 100%;
  color: var(--primary-color-text) !important;
}

.content-left-first {
  width: 50%;
  display: flex;
  align-items: center;
  font-family: "pop-Bold";
  font-size: 18px;
}

.content-left {
  width: 50%;
  font-family: "pop-Semibold";
  font-size: 14px;
}

.text-right {
  /* white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden; */
}

.content-right-first {
  width: 50%;
  text-align: end;
  font-family: "pop-Light";
  font-size: 14px;
  margin-left: 5px;
  position: relative !important;
}

.content-right {
  width: 50%;
  text-align: end;
  font-family: "pop-Light";
  font-size: 14px;
  margin-left: 5px;
}

.content-right {
  overflow: auto;
}

.content-right::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.content-right::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.content-right::-webkit-scrollbar-thumb {
  background: var(--primary-color-scroll);

  border-radius: 10px;
}

.content-right::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color-scroll);
}

.content-table-mobile {
  background-color: var(--primary-color-background-table) !important;
  box-shadow: 0px 0px 10px 5px var(--primary-color-border-shadow) !important;
  border-radius: 10px;
}

.content-title {
  text-align: start;
}

.text-head {
  color: var(--primary-color-text) !important;
}

.content-text {
  /* text-align: end; */
}

.text-response {
  color: var(--primary-color-text) !important;
}

.text-menu-accion {
  font-family: "pop-Semibold";
  font-size: 14px;
  margin-left: 5px;
}

.content-accion-menu {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
}

.content-accion {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
</style>